import React from 'react';
import { Typography } from '@material-ui/core';
import PortableText from '@sanity/block-content-to-react';
import styled from 'styled-components';
import {
  Card,
  CardMedia,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
} from './card';

const PricingText = styled(Typography)`
  font-size: 1.5rem;
  small {
    font-size: 0.75rem;
    color: grey;
  }
`;

const ServiceCard = ({ smallcard, service }) => (
  <Card smallcard={smallcard}>
    <CardActionArea link={service.slug == null ? '/' : service.slug.current}>
      {service.image == null ? (
        ''
      ) : (
        <CardMedia
          smallcard={smallcard}
          fluidImage={service.image.asset.fluid}
        />
      )}

      <CardHeader
        disableTypography
        title={
          <Typography
            variant={!smallcard ? 'h4' : 'h5'}
            component="h3"
            align="center"
          >
            {service.heading == null ? 'Service Heading' : service.heading}
          </Typography>
        }
      />

      <CardContent smallcard={smallcard}>
        <PricingText
          variant={!smallcard ? 'h4' : 'subtitle1'}
          component="h3"
          color="secondary"
          align="center"
          gutterBottom
        >
          {service.pricingSection ? (
            <>
              <small>From</small> $
              {service.pricingSection.servicePriceList[0] != null
                ? service.pricingSection.servicePriceList[0].cost.toFixed(2)
                : ''}
              <small> plus HST</small>
            </>
          ) : (
            'Contact For Pricing'
          )}
        </PricingText>
        {smallcard ? (
          ''
        ) : (
          <Typography
            variant="body1"
            align="left"
            component={PortableText}
            blocks={
              service._rawDescription == null
                ? 'Service description'
                : service._rawDescription
            }
          />
        )}
      </CardContent>
    </CardActionArea>
    <CardActions
      link={service.slug == null ? '/' : `${service.slug.current}/#description`}
    >
      Read More
    </CardActions>
  </Card>
);

export default ServiceCard;
