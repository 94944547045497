import React from 'react';
import { Link } from 'gatsby';
import { Typography, Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import ServiceCard from '../cards/serviceCard';
import Section from './section';

const ServiceSection = styled(Section)`
  padding: 4rem 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 4rem 0 4rem 0;
  }
`;

// const Button = styled(MuiButton)``;

const Services = ({ services }) => {
  const numberOfServices = services.serviceList.length;
  return (
    <ServiceSection maxWidth="lg">
      <Grid container justify="center" spacing={6}>
        <Grid item>
          <Typography variant="h2" align="center">
            {services.heading}
          </Typography>
          <Typography variant="h6" align="center">
            Amazing services for any pet.
          </Typography>
        </Grid>
        <Grid item container spacing={6}>
          <Grid item container warp="nowrap" justify="center" spacing={4}>
            {services.serviceList.map((service, index) => {
              if (index < 3) {
                return (
                  <Grid item xs={12} sm={6} md={4} key={`top-service-${index}`}>
                    <ServiceCard service={service} />
                  </Grid>
                );
              }
            })}
          </Grid>
          <Grid item container wrap="wrap" justify="center" spacing={4}>
            {services.serviceList.map((service, index) => {
              if (index >= 3) {
                return (
                  <Grid item xs={6} sm={3} md={3} key={`top-service-${index}`}>
                    <ServiceCard smallcard body service={service} />
                  </Grid>
                );
              }
            })}
            <Grid item xs={12} container justify="center">
              <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/services"
              >
                All Services
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ServiceSection>
  );
};

export default Services;
