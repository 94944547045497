import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Reviews from '../components/sections/reviews';
import Services from '../components/sections/landingServices';
import Request from '../components/sections/request';
import Awards from '../components/sections/awards';
import Quote from '../components/sections/quote';
import SEO from '../components/seo';

const IndexPage = () => {
  const pageData = useStaticQuery(graphql`
    {
      sanitySettings {
        _rawSiteMessage
        logo {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      sanityLandingPage {
        name
        heading
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        serviceSection {
          heading
          subheading
          serviceList {
            name
            heading
            _rawDescription
            pricingSection {
              heading
              subheading
              servicePriceList {
                cost
                duration
                durationType
                backgroundColor
              }
            }
            slug {
              current
            }
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        reviewSection {
          heading
          subheading
          googleReviewsToggle
          reviewList {
            name
            location
            reviewText
            image {
              asset {
                fluid(maxWidth: 150) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        awardSection {
          awardList {
            image {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        requestFormToggle
        quoteToggle
      }
    }
  `);

  const page = pageData.sanityLandingPage;

  return (
    <Layout
      scrollLogo
      heroButton={page.requestFormToggle}
      heroMessage={pageData.sanitySettings._rawSiteMessage}
      heroHeading={page.heading && page.heading}
      heroImage={page.image}
      heroLogo={pageData.sanitySettings.logo}
    >
      <SEO title="Home" />
      {/* Services */}
      <Services services={page.serviceSection} />
      {/* Reviews */}
      <Reviews reviews={page.reviewSection} />
      {/* Request Form */}
      {page.requestFormToggle && (
        <Request services={page.serviceSection.serviceList} />
      )}
      {/* Awards */}
      <Awards awards={page.awardSection} />
      {/* Quote */}
      {page.quoteToggle && <Quote />}
    </Layout>
  );
};

export default IndexPage;
