import React from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';
import Section from './section';

import Review from '../cards/reviewCard';

const OuterContainer = styled.div`
  position: relative;
  left: -2.5%;
  background-color: ${props => props.theme.palette.primary.main};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  width: 105%;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  border-top-right-radius: 50% 2rem;
  margin-top: -15px;
  overflow: hidden;
`;

const InnerContainer = styled.section`
  background-color: white;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  left: -2.5%;
  width: 105%;
  padding: 4rem 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  border-top-right-radius: 50% 2.5rem;
`;

const Reviews = ({ reviews }) => (
  <OuterContainer>
    <InnerContainer>
      <Section maxWidth="lg">
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={8}
        >
          <Grid item>
            <Typography variant="h2" align="center">
              {reviews.heading}
            </Typography>
            <Typography variant="h4" align="center">
              {reviews.subheading}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify="center"
            alignItems="stretch"
            spacing={6}
          >
            {reviews.reviewList.map((review, index) => (
              <Review key={index} review={review} />
            ))}
          </Grid>
        </Grid>
      </Section>
    </InnerContainer>
  </OuterContainer>
);
export default Reviews;
