import React from 'react';
import styled from 'styled-components';
import { Typography, Grid, Badge } from '@material-ui/core';
import Img from 'gatsby-image';
import { FaQuoteLeft } from 'react-icons/fa';

const Image = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

const ReviewText = styled(Typography)``;

const ReviewCard = ({ review }) => (
  <Grid
    item
    xs={9}
    md={4}
    container
    direction="row"
    alignItems="center"
    justify="center"
    spacing={2}
  >
    <Grid item xs={12} container justify="center">
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        badgeContent={<FaQuoteLeft color="white" />}
        color="primary"
      >
        {review.image && (
          <Image
            fluid={review.image.asset.fluid}
            alt="service.heading service image"
          />
        )}
      </Badge>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        {review.reviewText && review.reviewText}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="h5" color="secondary" align="center">
        {review.name && review.name}
      </Typography>
      <Typography variant="body2" align="center">
        {review.location ? 'Ottawa' : review.location}
      </Typography>
    </Grid>
  </Grid>
);

export default ReviewCard;
