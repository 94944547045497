import React from 'react';
import { Grid } from '@material-ui/core';
import Img from 'gatsby-image/withIEPolyfill';
import styled from 'styled-components';
import Section from './section';

const ServiceSection = styled(Section)`
  padding: 4rem 2rem 4rem 2rem;
  width: 100%;
  ${props => props.theme.breakpoints.up('lg')} {
    padding: 4rem 0 4rem 0;
  }
`;

const Awards = ({ awards }) => (
  <ServiceSection maxWidth="lg" id="awardsCertification">
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={8}
    >
      {awards.awardList.length === 0
        ? ''
        : awards.awardList.map((award, index) => (
            <Grid key={`award-certificate-${index}`} item xs={9} sm={6} md={4}>
              {award.image && (
                <Img
                  fluid={award.image.asset.fluid}
                  objectFit="contain"
                  alt="Award Certificate"
                />
              )}
            </Grid>
          ))}
    </Grid>
  </ServiceSection>
);
export default Awards;
