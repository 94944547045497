import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import {
  CardHeader as MuiCardHeader,
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
  CardActions as MuiCardActions,
  Button,
} from '@material-ui/core';

const StyledCard = styled(MuiCard)`
  /* margin: ${props => (props.smallcard ? '0.5rem' : '')}; */
  padding: ${props => (props.smallcard ? '0' : '0.5rem')};
  background: ${props => props.theme.palette.primary.light};
`;

const Media = styled(MuiCardMedia)`
  height: ${props => (props.smallcard ? '151px' : '300px')};
`;

const Content = styled(MuiCardContent)`
  overflow: hidden;
  background: white;
  padding: 0.5rem 1rem;
  margin: 0;
  & * p {
    margin: 0;
  }
  height: ${props => (props.smallcard ? '3rem' : '7.7rem')};

  &:after {
    content: '';
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40%;
    height: ${props => (props.smallcard ? '0' : '1.5rem')};
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 50%
    );
  }
`;

const Header = styled(MuiCardHeader)`
  background: ${props => props.theme.palette.primary.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px -4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  padding: 0.25rem;
  height: 3.5rem;
`;

const Actions = styled(MuiCardActions)`
  background: white;
  display: flex;
  justify-content: flex-end;
`;

// Exported Components

export const Card = ({ smallcard, children }) => (
  <StyledCard raised smallcard={smallcard}>
    {children}
  </StyledCard>
);

export const CardActionArea = ({ link, children }) => (
  <MuiCardActionArea component={Link} to={`/${link}`}>
    {children}
  </MuiCardActionArea>
);

export const CardHeader = ({ title, subTitle }) => (
  <Header disableTypography title={title} subheader={subTitle} />
);

export const CardMedia = ({ smallcard, fluidImage, objectPosition }) => (
  <Media
    smallcard={smallcard}
    component={Img}
    objectPosition={objectPosition}
    fluid={fluidImage}
    // objectFit="contain"
  />
);

export const CardContent = ({ smallcard, children }) => (
  <Content smallcard={smallcard}>{children}</Content>
);

export const CardActions = ({ link, children }) => (
  <Actions disableSpacing>
    <Button component={Link} to={`/${link}`} size="small" color="primary">
      {children}
    </Button>
  </Actions>
);
